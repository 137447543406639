.sync-date-time {
  color: '#172B4D' !important;
  font-size: 14;
  font-weight: 400;
  line-height: 16px;
  text-align: right;
  margin-bottom: 0;
}
.add-license-modal {
  border-radius: 4px;
  width: 37.5vw;
  height: 21.7vh;
  .ant-modal-title {
    font-size: 18px;
    line-height: 24px;
    color: #172b4d;
  }
  .ant-modal-body {
    padding: 1rem;
  }
  .ant-form-item {
    margin-bottom: 0px;
  }
  .license-url-input {
    border-radius: 4px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2px;
    height: 44px;
  }
  .cancel-btn {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #e8ebf1;
    border-radius: 4px;
    width: 97px;
    height: 48px;
  }
  .add-license-cancel-btn {
    color: #1D1E23 !important;
    border-color: #CED2DB !important;
    background-color: #FFFFFF !important;
  }
  .ok-btn,
  .ok-btn1 {
    border-radius: 4px;
    padding: 12px 50px;
    background: #0073e6;
    padding-top: 9px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    width: 157px;
    height: 48px;
  }
  .ok-btn1,
  .ok-btn1:hover {
    color: #8D95A3 !important;
    background-color: #E0E3E9 !important;
    font-weight: 400 !important;
  }
}
