:root {
  --color-gray-1: #EDEFF4;
  --color-yellowOrange: #FF9500;
}

.ekbBreadcrumb {
  margin-bottom: 1rem;
}

.ekbBreadcrumb > ol {
  align-items: center;
}

.ebkButtonLink {
  padding: 0;
  margin: 0;
}
.ebkButtonLink:hover {
  background-color: transparent;
}

/*Need repeat this properties because the button same time is encapsuled in <span>*/
.ekbButtonShortCut, .ekbButtonShortCut > button {
  width: 2rem;
  height: 2rem;
  padding: 0;
  text-align: center;
  border-radius: 4px;
}

.ekbButtonDisabled > button, button.ekbButtonDisabled {
  background-color: #878787 !important;
}

.ekbButton {
  border-radius: 4px;
  height: 3rem;
}

button[class="ant-btn ant-btn-default ekbButton"] {
  background-color: var(--color-gray-1) !important;
  border-radius: 4px;
  height: 3rem;
}

button[class="ant-btn ant-btn-primary ekbButton"] {
  border-radius: 4px;
  height: 3rem;
}

button[class="ant-btn ant-btn-primary ant-btn-dangerous ekbButton"] {
  border-radius: 4px;
  height: 3rem;
  border: 1px solid #ff6b72 !important;
  background-color: #ff4d4f !important;
}

.ekbSelect > .ant-select-selector {
  height:3rem !important;
  border-radius: 4px !important;
}

.ekbSelect > .ant-select-selector > .ant-select-selection-placeholder {
  line-height:3rem !important;
}


span[class~="ekbInput"],
input[class~="ekbInput"] {
  width: 100%;
  height: 3rem;
  padding: 12px;
  border-radius: 4px;
  border: 1px solid #E0E3E9;
}


.ekbInput[type="number"]:hover {
  appearance: textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.ekbInputNumber {
  width: 100px;
	height: 48px;
	padding: 4px;
	border-radius: 4px;
	border: 1px solid	#E0E3E9
}

.ekbInputNumberAffixWrapper {
	padding: 0px 0px 0px 10px!important;
}

.ekbInputNumber .ant-input-number-affix-wrapper .ant-input-number-affix-wrapper-status-success{
  width: 100px;
	height: 48px;
	padding: 4px;
	border-radius: 4px;
	border: 1px solid	#E0E3E9
}

/* Show arrows to InputNumber >> .ant-input-number-handler-wrap */
.ekbInputNumber > .ant-input-number-handler-wrap {
  opacity: 1;
}

/* Show arrows to InputNumber >> with Affix.ant-input-number-handler-wrap */
.ekbInputNumber > .itemInputNumber > .ant-input-number-handler-wrap {
  opacity: 1;
  background-color: transparent;
}

.ekbInputNumber > .itemInputNumber > .ant-input-number-input-wrap {
  line-height: 3.3;
}


.ekbInputNumber > .itemInputNumber {
  padding-left: 25px !important;
  background-color: transparent;
}

.ekbModal, .ekbReturnHomeModal > .ant-modal-content{
  text-align: center;
}

.ekbModal, .ekbReturnHomeModal > .ant-modal-content > .ant-modal-footer {
  display: flex;
  flex-direction: column;
}

.ekbModal,
.ekbReturnHomeModal >
.ant-modal-content >
.ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger){
  margin-top: 0.5rem !important;
  margin-left: 0px !important;
}

/* Icons */
.ekbIconFs-2 {
  font-size: 2rem;
}

.ekbIconColorYellow {
  color: var(--color-yellowOrange);
}
