.ant-progress {
  line-height: $line-height-base;
  color: $body-color;
}

.ant-progress-inner {
  background-color: $progress-bg;
  border-radius: $progress-border-radius;
  vertical-align: middle;
  position: relative;
}

.ant-progress-line {
  width: 100%;
  font-size: $font-size-base;
  position: relative;
}

.ant-progress-bg {
  border-radius: $progress-border-radius;
  background-color: $progress-bar-bg;
  position: relative;
}

.ant-progress-circle-trail {
  stroke: $progress-bg;
}

.ant-progress-circle-path {
  stroke: $progress-bar-bg;
}

.ant-progress-inner {
  &:not(.ant-progress-circle-gradient) {
    .ant-progress-circle-path {
      stroke: $progress-bar-bg;
    }
  }
}

.ant-progress-text {
  width: 2em;
  font-size: 1em;
  color: $body-color;
  line-height: 1;
}

.ant-progress-status-exception {
  .ant-progress-bg {
    background-color: $danger;
  }

  .ant-progress-text {
    color: $danger;
  }

  .ant-progress-circle-path {
    stroke: $danger;
  }

  .ant-progress-inner {
    &:not(.ant-progress-circle-gradient) {
      .ant-progress-circle-path {
        stroke: $danger;
      }
    }
  }
}

.ant-progress-status-success {
  .ant-progress-bg {
    background-color: $success;
  }

  .ant-progress-text {
    color: $success;
  }

  .ant-progress-circle-path {
    stroke: $success;
  }

  .ant-progress-inner {
    &:not(.ant-progress-circle-gradient) {
      .ant-progress-circle-path {
        stroke: $success;
      }
    }
  }
}

.ant-progress-success-bg {
  background-color: $success;
}

.ant-progress-circle {
  .ant-progress-text {
    display: block;
    position: absolute;
    width: 100%;
    text-align: center;
    line-height: 1;
    top: 50%;
    @include transform(translateY(-50%));
    left: 0;
    margin: 0;
    color: $gray-dark;
  }

  &.ant-progress-status-success {
    .ant-progress-text {
      color: $success;
    }
  }
}

.ant-progress-small {
  &.ant-progress-line {
    font-size: 12px;

    .ant-progress-text {
      .anticon {
        font-size: 12px;
      }
    }
  }

  .ant-progress-bg {
    height: 4px !important;
  }
}
