.ant-picker-calendar {
  color: $body-color;
  font-size: $font-size-base;
  line-height: $line-height-base;
  background: $white;

  .ant-picker-panel {
    .ant-picker-body {
      padding: 0.625rem 0;
    }

    .ant-picker-content {
      tbody {
        border-left: 1px solid $picker-calendar-border-color;
        border-bottom: 1px solid $picker-calendar-border-color;
      }
    }
  }

  &.ant-picker-calendar-mini {
    .ant-picker-panel {
      border-top: 1px solid $picker-calendar-border-color;

      .ant-picker-content {
        tbody {
          border-left: 0;
          border-bottom: 0;
        }
      }
    }
  }
}

.ant-picker-calendar-full {
  .ant-picker-panel {
    .ant-picker-calendar-date {
      margin: 0px;
      padding: 4px 8px 0;
      border-top: 1px solid $picker-calendar-border-color;
      border-right: 1px solid $picker-calendar-border-color;

      @include screen-mobile {
        padding-left: 4px;
        padding-right: 4px;
      }
    }

    .ant-picker-calendar-date-value {
      display: inline-block;
      line-height: 2;

      @include screen-mobile {
        line-height: 1.5;
      }
    }

    .ant-picker-calendar-date-content {
      height: 86px;
      color: $body-color;
      line-height: $line-height-base;

      @include screen-laptop-only {
        height: 70px;

        .calendar-event {
          .ant-badge {
            display: flex;
            align-items: baseline;
          }

          .ant-badge-status-dot {
            min-width: $badge-dot-size;
          }

          .ant-badge-status-text {
            font-size: $font-size-sm;
          }
        }
      }

      @include screen-mobile {
        height: 30px;
      }

      @include screen-tablet {
        height: 40px;
      }
    }

    .ant-picker-cell {
      &:hover {
        .ant-picker-calendar-date {
          background: $picker-calendar-hover-bg;
        }
      }

      .ant-picker-cell-inner {
        @include screen-mobile {
          line-height: 1;
          font-size: 12px;
        }
      }
    }

    .ant-picker-cell-selected {
      .ant-picker-calendar-date,
      .ant-picker-calendar-date-today {
        background: $picker-calendar-selected-bg;

        .ant-picker-calendar-date-value {
          color: $picker-calendar-theme-color;
          font-weight: $font-weight-semibold;
        }
      }

      &:hover {
        .ant-picker-calendar-date,
        .ant-picker-calendar-date-today {
          background: $picker-calendar-selected-bg;

          .ant-picker-calendar-date-value {
            color: $picker-calendar-theme-color;
          }
        }
      }
    }

    .ant-picker-calendar-date-today {
      .ant-picker-calendar-date-value {
        background-color: $picker-calendar-theme-color;
        color: $white !important;
        font-weight: $font-weight-base !important;
        border-radius: $border-radius-lg;
        padding: 0rem 0.408rem;

        @include screen-mobile {
          padding: 0rem 0.2rem;
        }
      }
    }
  }
}

.ant-picker-calendar-header {
  padding: 0.9375rem 0;
}
