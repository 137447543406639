.custom-card {
  .card-title {
    color: $text-dark-primary;
    font-size: 16px; // 16px
    text-align: left;
  }
  .ant-card-head-title {
    font-weight: 500;
    line-height: 24px;
  }
  .ant-card-head,
  .fs-md {
    font-size: 16px; // 16px
  }
  .ant-btn {
    height: 44px;
  }
  .fs-normal {
    font-size: 14px !important; // 14px
  }
  .text-muted {
    color: $text-dark-secondary !important;
  }
  .text-sm {
    font-size: 12px; // 12px
  }
  .bg-blue-dark {
    background-color: $blue-dark;
  }
  .dark-primary {
    color: $text-dark-primary !important;
  }
  .ant-card-body {
    padding-bottom: 1.25rem;
  }
  .bg-white {
    background-color: $neutrals-n0;
  }
  .min-height-50 {
    min-height: 50px;
  }
  .ant-input {
    font-size: 16px;
  }
  a.ant-btn {
    // padding-top:5px !important;
  }
  &:hover {
    cursor: default !important;
  }

  .links-btn,
  .disabled-update-device-btn button {
    border-radius: 4px;
    line-height: 24px;
    padding: 10px 32px !important;
    margin-top: 18px;
    color: #0073e6;
    border-color: #0073e6;
  }
  .disabled-update-device-btn button {
    margin-top: 0;
    color: #c1c7d0;
    border-color: #c1c7d0;
  }
  .tab-edit-btn .ant-card-extra {
    // left: '1vw', color: '#0052CC', fontWeight: 500, backgroundColor: 'white', paddingTop:0
    padding-top: 20px;
    color: $blue-dark; // #0052CC
    button {
      left: 1vw;
      padding-top: 0;
      font-weight: 500;
      color: $blue-dark; // #0052CC
      &:hover {
        background-color: $white;
      }
    }
  }
}

@for $i from 0 through 10 {
  .fw-b#{$i} {
    font-weight: $i * 100;
  }
}

.card-border {
  border: 1px solid #e0e3e9;
}

.card-tab .ant-tabs-tab {
  border: 1px solid #e0e3e9;
}

.card-bg-white {
  background-color: $white;
  .ant-card-body,
  .ant-card-head {
    background-color: $white;
  }
}

.data_card {
  .ant-card-head-title {
    color: $text-dark-primary !important;
  }
}

.video-card {
  .ant-card-head-title {
    font-size: 16;
    font-weight: 400;
  }
}

.license-card {
  text-align: center;
  padding: 15px;
  h4 {
    margin: 35px auto 20px;
  }
  .license-count {
    font-size: 40px;
  }
}
.disable-row {
  color: #c1c7d0;
}

.extra-padding-right {
  padding-right: 17px;
}

.empty-table-text {
  color: '#172B4D';
  font-size: 14px;
  font-weight: 500;
}

.search-btn {
  background-color: #f5f5f5;
  box-sizing: border-box;
  border: 1px solid #dfe1e6;
  border-radius: 4px;
}

.new-appSetup-card {
  .ant-radio-wrapper {
    margin-bottom: 0.75rem;
  }
  .newSetup-btn-blk {
    display: flex;
    align-items: center;
    padding-top: 6px;
  }
}

.new-setting-btn {
  background-color: $blue-dark; // #0052CC
  color: $white;
  float: right;
  text-align: center;
  padding-left: 9px;
  padding-right: 16px;
  width: 130px;
  height: 44px;
  // padding: 12px 12px;
  border-radius: 4px;
  line-height: 24px;
}
.card-table-border {
  border: 1px solid #e0e3e9;
  border-radius: 0;
}
