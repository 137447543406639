.ant-drawer {
  z-index: $zindex-modal;
}

.ant-drawer-header {
  padding: $modal-header-padding;
  border-radius: 4px 4px 0 0;
  background: $white;
  color: $body-color;
  border-bottom: 1px solid $border-color;
}

.ant-drawer-footer {
  position: absolute;
  bottom: 0px;
  width: 100%;
  border-top: 1px solid $border-color;
  padding: $modal-footer-padding;
  left: 0px;
  background: $white;
}

.card-header {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #172b4d;
  text-align: left;
}
.card-above-description {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 143%;
  letter-spacing: 0.2px;
  color: #5e6c84;
  text-align: left;
  margin-bottom: 8px;
}
.card-below-description {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 133%;
  letter-spacing: 0.4px;
  color: #5e6c84;
  text-align: left;
}
.card-label {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.2px;
  color: #172b4d;
  text-align: left;
  margin-bottom: 8px;
}

.card-label-required .ant-form-item-required {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.2px;
  color: #172b4d;
  text-align: left;
  margin-bottom: 8px;
  display: flow-root;
}
.card-label-required .anticon {
  margin-top: -5px;
}

.card-select-dropdown.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background: #fafbfc;
  border: 1px solid #dfe1e6;
  box-sizing: border-box;
  border-radius: 4px;
}
.card-select-dropdown.ant-select {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #5e6c84;
}
.textInput.ant-input-affix-wrapper {
  background-color: #fafbfc;
  border-radius: 4px;
  border: 1px solid #dfe1e6;
  box-sizing: border-box;
}
.textInput .ant-input,
.textAreaInput {
  color: #5e6c84;
  background-color: #fafbfc;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  border-radius: 4px;
}
.textInput-button {
  .ant-btn.ant-btn-primary.ant-input-search-button {
    height: 45px;
    border-left: 0 4px 4px 0;
    background-color: #0073e6;
    font-weight: 500;
  }
  .ant-input {
    color: #5e6c84;
    background-color: #fafbfc;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2px;
    border-radius: 4px;
  }
}
.timepicker-datepicker {
  width: 250px;
  border-radius: 4px;
  background: #fafbfc;
  border-color: 1px solid #dfe1e6;
}
.reviewSpanTitle {
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #172b4d;
  margin-bottom: 4px;
}

.reviewSpanValue {
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #5e6c84;
  word-break: break-word;
}
.br-4 {
  border-radius: 4px;
}
.b-0 {
  border: 0;
}
.card-below-desc {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 133%;
  letter-spacing: 0.4px;
  color: #5e6c84;
}
