/* .ant-menu > .customHover:hover{
  color: #3C414B;
  background: #DEEBFF;
} */

.ant-menu>.customHover:not(.ant-menu-item-selected):hover {
  color: #3C414B !important;
  background: #DEEBFF;
}

.ant-menu>.ant-menu-item-selected.customHover {
  color: #ffffff;
}

.ant-menu-submenu .ant-menu>.customHover:not(.ant-menu-item-selected):hover {
  color: #3C414B;
  background: #DEEBFF;
}

.ant-menu-submenu .ant-menu>.ant-menu-item-selected.customHover {
  color: #ffffff;
}

.ant-menu-submenu .ant-menu>.ant-menu-item-selected.customHover svg path {
  fill: #ffffff
}

.ant-menu-submenu.ant-menu-submenu-active:hover div {
  color: #3C414B !important;
}

.ant-menu-submenu.ant-menu-submenu-active:hover div i::before {
  background-image: linear-gradient(to right, #3C414B, #3C414B) !important;
  background-image: linear-gradient(to left, #3C414B, #3C414B) !important;
}

.ant-menu-submenu.ant-menu-submenu-active:hover div i::after {
  background-image: linear-gradient(to right, #3C414B, #3C414B) !important;
  background-image: linear-gradient(to left, #3C414B, #3C414B) !important;
}


/* .ant-menu > .ant-menu-item:hover,
.ant-menu > .ant-menu-submenu:hover,
.ant-menu > .ant-menu-item-active,
.ant-menu> .ant-menu-submenu-active,
.ant-menu > .ant-menu-item-open,
.ant-menu> .ant-menu-submenu-open,
.ant-menu > .ant-menu-item-selected,
.ant-menu > .ant-menu-submenu-selected {
  color: red;
  border-bottom: 2px solid red;
} */