.mail {
  .mail-list {
    .ant-table-row {
      cursor: pointer;
    }

    .ant-table-selection {
      padding: 0.3rem 0;
    }

    .mail-list-star {
      padding-right: 15px;
      padding-left: 0;
      cursor: pointer;

      &.checked {
        color: $warning;
      }

      &.uncheck {
        color: $text-muted;
      }

      &:hover {
        color: $warning;
      }
    }

    .mail-list-sender {
      width: 220px;
      padding: 15px 0;
    }

    .mail-list-content {
      max-width: 0;

      .mail-list-content-msg {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .mail-list-date {
      width: 100px;
      text-align: right;
    }

    .mail-list-action {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .mail-list-action-icon {
      font-size: $font-size-lg;
      color: $gray;
      cursor: pointer;
      margin-left: 1.2rem;
    }
  }

  .mail-detail {
    .mail-detail-action {
      display: flex;
      align-items: center;
    }

    .mail-detail-action-icon {
      margin-left: 0.75rem;
      font-size: $font-size-lg;
      cursor: pointer;

      &.star {
        &.checked {
          color: $warning;
        }

        &:hover {
          color: $warning;
        }
      }

      &:hover {
        color: $primary;
      }
    }

    .mail-detail-content {
      margin-top: 1.25rem;
    }

    .mail-detail-attactment {
      display: flex;
      margin-top: 30px;

      .mail-detail-attactment-item {
        cursor: pointer;
        border-radius: $border-radius-lg;
        border: 1px solid $border-color;
        padding: 5px 10px;
        margin-right: 15px;
        display: flex;
        align-items: center;

        .anticon {
          font-size: 2rem;
        }
      }
    }
  }

  .mail-compose {
    .ql-container {
      min-height: 9.5rem;
      border-top: 1px solid $border-color !important;
      border-bottom: 0px;
    }

    .ql-editor {
      min-height: 9.5rem;
    }

    .editor-wrapper {
      position: relative;
    }

    .ql-toolbar {
      position: absolute;
      bottom: 0;
      width: 100%;
      transform: translateY(100%);
      z-index: 1;
    }
  }
}
