.ant-switch {
  font-size: $font-size-base;
  color: $body-color;
  line-height: $line-height-base;
  height: $switch-height;
  min-width: $switch-min-width;

  &:after {
    width: $switch-size;
    height: $switch-size;
    border-radius: $switch-size;
    background-color: $white;
  }

  &:before {
    width: $switch-size;
    height: $switch-size;
    font-size: $switch-loading-size;
  }
}

.ant-switch-checked {
  background-color: $switch-theme-color;

  &:after,
  &:before {
    left: 100%;
  }

  &:before {
    margin-left: -$switch-size - 1px;
  }
}

.ant-switch-small {
  height: $switch-height-sm;
  min-width: $switch-min-width-sm;
  line-height: $line-height-base;

  &:after {
    width: $switch-size-sm;
    height: $switch-size-sm;
    border-radius: $switch-size-sm;
  }

  &:before {
    width: $switch-size-sm;
    height: $switch-size-sm;
    font-size: $switch-loading-size-sm;
  }

  &.ant-switch-checked {
    &:after,
    &:before {
      left: 100%;
      margin-right: -$switch-size-sm - 1px;
    }
  }

  .ant-switch-handle {
    top: 1.5px;
  }
}

.ant-switch-inner {
  color: $white;
  font-size: $switch-font-size;
  margin-left: $switch-size + 6px;
  margin-right: 6px;
  line-height: $switch-size;
}

.ant-switch-checked .ant-switch-inner {
  margin-left: 6px;
  margin-right: $switch-size + 6px;
}

.ant-switch-handle {
  top: 3px;
  left: 3px;
}
